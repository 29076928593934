<template>
  <commonHeader></commonHeader>
  <div class="wrap">
    <div class="article">
      <h1 class="title">{{article.name}}</h1>
      <div class="c-teacher ohidden">
        <div
          class="fr color-999"
          v-if="publishTime"
        >{{dates.formatDate(publishTime*1, 'yyyy-MM-dd hh:mm')}}</div>
        <div class="c-thumb fl">
          <img width="32" height="32" :src="article.teacherHeaderImage" alt />
        </div>
        <div class="c-teacher-name fl">{{article.teacherName}}</div>
        <div class="c-teacher-title fl">{{article.teacherTitle}}</div>
      </div>
      <img width="950" src="~@/assets/images/article-top.jpg" alt />
      <div class="c-head-img">
        <img width="950" :src="article.image" alt />
      </div>
      <div class="c-author-info">
        <div>
          <span class="mr20" v-if="article.teacherName">文章作者 | {{article.teacherName}}</span>
          <span v-if="article.editorName">责任编辑 | {{article.editorName}}</span>
        </div>
        <div>
          <span class="mr20" v-if="article.reviewerName">审校 | {{article.reviewerName}}</span>
          <span v-if="article.noteTalentName">笔记达人 | {{article.noteTalentName}}</span>
        </div>
        <div>
          <span class="mr20" v-if="article.videoEditorName">拍摄剪辑 | {{article.videoEditorName}}</span>
          <span v-if="article.audioEditorName">音频 | {{article.audioEditorName}}</span>
        </div>
      </div>
      <div class="content" v-html="article.content"></div>
      <div class="line"></div>
      <div class="color-999 pt30">*文章为作者独立观点，不代表笔记侠立场。</div>
      <div class="tr color-999 pt30 mt30">©著作权归作者所有，转载请联系笔记侠客服</div>
    </div>
  </div>
  <commonFooter></commonFooter>
</template>

<script>
import dates from '@/utils/dates'
import commonHeader from '../public/header.vue'
import commonFooter from '../public/footer.vue'
export default {
  components: {
    commonHeader,
    commonFooter,
  },
  data() {
    return {
      article: {},
      dates,
      publishTime: 0,
    }
  },
  created() {
    this.publishTime = this.$route.query.publishTime
    this.getOneArticle()
  },
  methods: {
    async getOneArticle() {
      let res = await this.api.getOneArticle(this.$route.query.id)
      if (res && res.code == 200) {
        this.article = res.data
      }
    },
  },
}
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  padding: 38px 0 62px;
  background: #f9f9f9;
  .article {
    width: 950px;
    margin: 0 auto;
    .title {
      width: 100%;
      font-size: 26px;
      line-height: 26px;
      color: #111;
      margin-bottom: 56px;
      font-weight: bold;
    }
    .c-teacher {
      padding: 40px 0;
      line-height: 32px;
      .c-thumb {
        width: 32px;
        height: 32px;
        img {
          border-radius: 50%;
        }
      }
      .c-teacher-name {
        font-size: 14px;
        margin: 0 14px;
        color: #666;
        display: inline-block;
      }
      .c-teacher-title {
        font-size: 14px;
        color: #c80000;
      }
    }
  }
}
.c-author-info {
  padding: 20px 0;
  color: #888;
  line-height: 40px;
}
</style>