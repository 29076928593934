
export default {
  _week: {
    en: ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'],
    zh: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
    fullNameEn: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  },
  _month: {
    en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    zh: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
    fullNameEn: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  },
  /*
   * 当前本地时间
   */
  now() {
    return new Date()
  },
  // /*
  //  * 本周一最早时间
  //  */
  // mondayFirst = () => _mondayFirst(),
  // /*
  //  * 本周一最晚时间
  //  */
  // mondayLast = () => _mondayLast(),
  // /*
  //  * 本周日最早时间
  //  */
  // sundayFirst = () => _sundayFirst(),
  // /*
  //  * 本周日最晚时间
  //  */
  // sundayLast = () => _sundayLast(),
  /*
   * 每个月的天数
   */
  daysPerMonth(year, month) {
    return (month == 2) ? (year % 4 == 0 ? 29 : 28) : ((month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12) ? 31 : 30)
  },
  daysPerMonthTime(date) {
    return this.daysPerMonth(date.getFullYear(), date.getMonth())
  },
  /*
   * 每天最早时间
   */
  dayFirst(date) {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    return date;
  },
  // dayFirst(date) {
  //   return date.subtract(Duration(hours: date.hour, minutes: date.minute, seconds: date.second, milliseconds: date.millisecond, microseconds: date.microsecond)),
  // }

  /*
   * 每天最晚时间
   */
  dayLast(date) {
    date = new Date(date);
    date.setHours(23, 59, 59, 999);
    return date;
  },
  // dayLast(date) {
  //   return date.add(Duration(hours: (23-date.hour), minutes: (59-date.minute), seconds: (59-date.second), milliseconds: (999-date.millisecond), microseconds: (999-date.microsecond))),
  // }
  /*
   * 每月最早时间
   */
  monthFirst(date) {
    return this.dayFirst(new Date(date.getFullYear(), date.getMonth(), 1))
  },
  /*
   * 每月最晚时间
   */
  monthLast(date) {
    return this.dayLast(new Date(date.getFullYear(), date.getMonth(), this.daysPerMonthTime(date)))
  },
  /**
   * @method monthName
   * @param  { number } month date对象返回的月份 0 ~ 30
   * @param  { string } lang 语言，判断返回中文还是英文 zh || en
   * @param  { string } type 如果传full返回完整版英文
   * @return { string } 月份字符串表示
   */
  monthName(month = (new Date()).getMonth(), lang = 'zh', type) {
    month = month * 1 + 1;
    if (type === 'full') {
      return this._month['fullNameEn'][month];
    }
    return this._month[lang][month];
  },
  /**
   * @method monthFullName
   * @param  { number } month date对象返回的月份 0 ~ 30
   * @return { string } 月份字符串表示
   */
  monthFullName(month = (new Date()).getMonth()) {
    return this._month['fullNameEn'][month];
  },
  /**
   * @method weekName 星期名称
   * @param  { string } lang 语言，判断返回中文还是英文 zh || en
   * @return 星期的字符串表示
   */
  weekName(weekday, lang = 'zh') {
    return this._week[lang][weekday];
  },
  weekFullName(weekday) {
    return this._week['fullNameEn'][weekday];
  },

  /**
   * @method getMinusYear 根据出生计算年龄
   */
  getMinusYear(utc) {
    let end = new Date();
    let start = this.getLocalDate(utc);
    if (start.getTime() > end.getTime()) {
      let temp = start;
      start = end;
      end = temp;
    }
    let yearStart = start.getFullYear() * 1;
    let yearEnd = end.getFullYear() * 1;
    let year = yearEnd - yearStart;
    let month;
    let monthStart = start.getMonth() * 1 + 1;
    let monthEnd = end.getMonth() * 1 + 1;
    if (monthEnd < monthStart) {
      month = monthStart - monthEnd;
    } else {
      month = (monthStart - monthEnd) * -1
    }
    year = year + month / 12;
    return year.toFixed(2);
  },
  getMinusDays(date1, date2) {
    var startDate = Date.parse(new Date(date1));
    var endDate = Date.parse(new Date(date2));
    if (startDate > endDate) {
      return 0;
    }
    if (startDate == endDate) {
      return 1;
    }
    var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
    return days;
  },
  formatDate(date, fmt = 'yyyy-MM-dd hh:mm', lang = 'zh') {
    if (!date) return '--'
    date = new Date(date)
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }

    let o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'w+': this._week[lang][date.getDay()]
    };

    for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        let str = o[k] + '';
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str));
      }
    }
    return fmt;
  },
  formatTimeLong(startTime, endTime) {
    if (!startTime || !endTime) return '-- ~ --'
    let time = ''
    let start = this.formatDate(new Date(startTime), 'yyyy-MM-dd')
    let end = this.formatDate(new Date(endTime), 'yyyy-MM-dd')
    if (start == end) {
      time =
        this.formatDate(new Date(startTime), 'yyyy-MM-dd hh:mm') +
        '  ～  ' +
        this.formatDate(new Date(endTime), 'hh:mm')
    } else {
      time =
        this.formatDate(new Date(startTime), 'yyyy-MM-dd hh:mm') +
        '  ～  ' +
        this.formatDate(new Date(endTime), 'yyyy-MM-dd hh:mm')
    }
    return time
  },
  padLeftZero(str) {
    return ('00' + str).substr(str.length);
  },
  getTimeZone() {
    let date = (new Date()).toString().substring(28);
    let arr = date.split('(');
    let times = arr[0].replace(' ', '');
    let time = times.substring(0, 3) + times.substring(3)
    // return arr[1].replace(')',' ') + time;
    return time
  },
  //
  getTimeString(string) {
    if (string || string !== undefined) {
      let arr = string.split('-')
      let maxDay = this.daysPerMonth(arr[0], arr[1])
      let date = [new Date(arr[0], arr[1] - 1, 1, 0, 0, 0, 0), new Date(arr[0], arr[1] - 1, maxDay, 23, 59, 59)]
      return date
    }
  },
  formatCourseDate(start, end) {
    if (!start && !end) return '--'
    let startDate = new Date(start)
    let sMonth = startDate.getMonth() * 1 + 1
    let sDay = startDate.getDate()

    let endDate = new Date(end)
    let eMonth = endDate.getMonth() * 1 + 1
    let eDay = endDate.getDate()

    let formatStr = ''

    if (sMonth != eMonth) {
      formatStr = sMonth + '月' + sDay + '日-' + eMonth + '月' + eDay + '日'
    } else {
      formatStr = sMonth + '月' + sDay + '日-' + eDay + '日'
    }
    return formatStr
  },
  formatCourseTimeCn(start, end) {
    if (!start && !end) return '--'
    let startDate = new Date(start)
    let sMonth = startDate.getMonth() * 1 + 1
    let sDay = startDate.getDate()
    let sHMS = this.formatDate(start, 'hh:mm')

    let endDate = new Date(end)
    let eMonth = endDate.getMonth() * 1 + 1
    let eDay = endDate.getDate()
    let eHMS = this.formatDate(end, 'hh:mm')

    let formatStr = ''

    if (sMonth != eMonth) {
      formatStr = sMonth + '月' + sDay + '日 ' + sHMS + '~' + eMonth + '月' + eDay + '日 ' + eHMS
    } else {
      formatStr = sMonth + '月' + sDay + '日-' + eDay + '日 ' + sHMS + '~' + eHMS
    }
    return formatStr
  },
  formatCourseTime(start, end) {
    if (!start && !end) return '--'
    let startDate = new Date(start)
    let sMonth = startDate.getMonth() * 1 + 1
    let sDay = startDate.getDate()
    let sHMS = this.formatDate(start, 'hh:mm')

    let endDate = new Date(end)
    let eMonth = endDate.getMonth() * 1 + 1
    let eDay = endDate.getDate()
    let eHMS = this.formatDate(end, 'hh:mm')

    let formatStr = ''

    if (sMonth != eMonth) {
      formatStr = sMonth + '.' + sDay + sHMS + '-' + eMonth + '.' + eDay + sHMS
    } else {
      formatStr = sMonth + '.' + sDay + sHMS + '-' + eDay + sHMS
    }
    return formatStr
  }

}
